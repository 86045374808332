<template>
  <header class="header">
    <div class="container header__container">
      <router-link class="header__logo" :to="{ name: 'main' }">
        <svg xmlns="http://www.w3.org/2000/svg" width="752" height="176" viewBox="0 0 752 176" fill="none">
          <path id="logo-bg" d="M748 172.5V3.5H3.5V172L748 172.5Z" fill="white" />
          <path
            d="M746.26 175.22H4.97002C2.30002 175.22 0.140015 173.05 0.140015 170.39V5.46989C0.140015 2.79989 2.31002 0.639893 4.97002 0.639893H746.26C748.93 0.639893 751.09 2.80989 751.09 5.46989V170.38C751.09 173.05 748.92 175.22 746.26 175.22ZM4.97002 4.63989C4.51002 4.63989 4.14001 5.00989 4.14001 5.46989V170.38C4.14001 170.84 4.51002 171.21 4.97002 171.21H746.26C746.72 171.21 747.09 170.84 747.09 170.38V5.46989C747.09 5.00989 746.72 4.63989 746.26 4.63989H4.97002Z"
          />
          <path
            d="M69.0101 125.63C66.6001 125.63 64.3301 125.19 62.2601 124.33C58.8301 122.9 56.01 120.71 53.88 117.84C50.52 113.29 50.0501 108.46 50.0701 105.98C50.0801 105.27 50.6701 104.69 51.3901 104.69H56.3901C57.1001 104.69 57.68 105.24 57.7 105.94C57.77 107.71 58.2901 111.12 60.9801 114.03C62.2701 115.42 63.8401 116.48 65.6601 117.19C66.8001 117.63 68.0501 117.86 69.3701 117.86H87.0301C88.4601 117.86 89.8301 117.46 91.0001 116.69C92.3901 115.78 93.4801 114.67 94.2401 113.39C95.4201 111.41 95.58 109.4 95.84 105.03C95.95 103.2 95.9401 102.04 95.9201 100.58C95.9101 99.99 95.9101 99.36 95.9101 98.63V51.68C95.9101 50.92 96.5401 50.29 97.3101 50.28L102.63 50.23C103.42 50.23 104.05 50.86 104.06 51.62L104.11 98.62C104.12 100.74 104.03 102.91 103.86 105.05C103.42 110.54 103.18 113.56 101.59 116.73C100.27 119.36 98.22 121.65 95.51 123.56L94.8901 123.96C93.1701 125.06 91.1801 125.64 89.1401 125.64H69.0101V125.63Z"
          />
          <path
            d="M128.95 125.54C128.58 125.54 128.28 125.23 128.28 124.86V50.9898C128.28 50.6198 128.58 50.3098 128.95 50.3098H174.65C175.02 50.3098 175.32 50.6098 175.32 50.9898V56.7398C175.32 57.1098 175.02 57.4198 174.65 57.4198H138.03C137.13 57.4198 136.4 58.1598 136.4 59.0698V82.0898C136.4 82.9998 137.13 83.7398 138.03 83.7398H161.88C162.25 83.7398 162.55 84.0398 162.55 84.4198V90.1198C162.55 90.4898 162.25 90.7998 161.88 90.7998H138.03C137.13 90.7998 136.4 91.5398 136.4 92.4498V116.8C136.4 117.71 137.13 118.45 138.03 118.45H174.76C175.13 118.45 175.43 118.75 175.43 119.13V124.88C175.43 125.25 175.13 125.56 174.76 125.56H128.95V125.54Z"
          />
          <path
            d="M255.21 69.3799C255.15 64.3299 253.11 59.4399 249.37 55.9599C249.09 55.6999 248.79 55.4299 248.48 55.1699C248.21 54.9399 247.93 54.7199 247.65 54.5099C243.82 51.5999 239.07 50.1799 234.26 50.1799H196.96C196.27 50.1799 195.71 50.7399 195.71 51.4299V123.8C195.71 124.84 196.55 125.69 197.6 125.69H233.75C236.94 125.69 240.1 124.96 242.91 123.48C244.61 122.58 246.46 121.39 248.29 119.81C249.26 118.97 250.12 118.12 250.88 117.27C254.01 113.78 255.65 109.25 255.6 104.61C255.47 92.8599 255.34 81.1199 255.21 69.3799ZM243.99 111.1C243.44 111.76 242.81 112.43 242.1 113.09C240.77 114.32 239.42 115.25 238.19 115.96C236.15 117.12 233.85 117.69 231.52 117.69H203.83V58.6899H232.53C234.24 58.6899 235.94 58.9899 237.53 59.6299C238.97 60.2099 240.63 61.1299 242.25 62.5799C242.48 62.7799 242.69 62.9899 242.9 63.1899C245.63 65.9199 247.11 69.7299 247.15 73.6799C247.24 82.8499 247.34 92.0299 247.43 101.2C247.46 104.83 246.27 108.37 243.99 111.1Z"
          />
          <path
            d="M278.77 125.54C278.4 125.54 278.1 125.23 278.1 124.86V50.9898C278.1 50.6198 278.4 50.3098 278.77 50.3098H324.47C324.84 50.3098 325.14 50.6098 325.14 50.9898V56.7398C325.14 57.1098 324.84 57.4198 324.47 57.4198H287.85C286.95 57.4198 286.22 58.1598 286.22 59.0698V82.0898C286.22 82.9998 286.95 83.7398 287.85 83.7398H311.7C312.07 83.7398 312.37 84.0398 312.37 84.4198V90.1198C312.37 90.4898 312.07 90.7998 311.7 90.7998H287.85C286.95 90.7998 286.22 91.5398 286.22 92.4498V116.8C286.22 117.71 286.95 118.45 287.85 118.45H324.58C324.95 118.45 325.25 118.75 325.25 119.13V124.88C325.25 125.25 324.95 125.56 324.58 125.56H278.77V125.54Z"
          />
          <path
            d="M400.04 125.63C392.73 116 385.42 106.37 378.11 96.74L354.58 65.79L354.56 125.62L346.57 125.57V50.23C348.65 50.23 350.73 50.23 352.81 50.23C368.67 71.15 384.53 92.07 400.4 112.99V50.23L407.93 50.28L407.88 125.62C405.26 125.63 402.65 125.63 400.04 125.63Z"
          />
          <path
            d="M458.4 125.6H445.48C432.1 125.6 421.22 114.67 421.22 101.25V74.62C421.22 61.19 432.1 50.26 445.48 50.26H458.4C470.33 50.26 477.96 57.78 479.33 70.89C479.47 72.18 478.53 73.34 477.23 73.48C475.92 73.61 474.78 72.68 474.64 71.38C474 65.25 471.03 54.97 458.39 54.97H445.47C434.69 54.97 425.93 63.78 425.93 74.61V101.24C425.93 112.07 434.7 120.88 445.47 120.88H458.39C470.34 120.88 473.93 111.31 475.01 105.6C475.25 104.32 476.49 103.48 477.76 103.72C479.04 103.96 479.88 105.19 479.64 106.47C477.35 118.63 469.61 125.6 458.4 125.6Z"
          />
          <path
            d="M533.01 125.66H520.23C506.82 125.66 495.91 114.75 495.91 101.34V74.53C495.91 61.12 506.82 50.21 520.23 50.21H533.01C546.42 50.21 557.33 61.12 557.33 74.53V101.34C557.33 114.75 546.42 125.66 533.01 125.66ZM520.23 54.91C509.41 54.91 500.62 63.71 500.62 74.52V101.33C500.62 112.15 509.42 120.94 520.23 120.94H533.01C543.83 120.94 552.62 112.14 552.62 101.33V74.52C552.62 63.7 543.82 54.91 533.01 54.91H520.23V54.91Z"
          />
          <path
            d="M578.46 125.68C577.72 125.68 577.12 125.07 577.12 124.33V51.3899C577.12 50.7199 577.66 50.1799 578.33 50.1799H614.58C617.08 50.2899 619.49 50.8299 621.74 51.7999C624.66 53.0499 626.57 54.7099 627.48 55.5099C628.38 56.2899 629.88 57.5899 631.19 59.5599C632.91 62.1299 633.89 65.1299 634.11 68.4799L634.48 103.11C634.52 106.54 633.9 109.73 632.65 112.57C632.39 113.16 631.96 114.15 631.19 115.35C630.9 115.8 630.01 117.18 627.83 119.23C626.28 120.69 624.82 122.06 622.64 123.27C620.07 124.7 617.19 125.51 614.08 125.7H614.07H578.46V125.68ZM581.88 120.94H611.79C614.5 120.94 617.14 120.23 619.43 118.89C620.97 117.99 622.42 116.89 623.73 115.64C624.46 114.94 625.16 114.19 625.8 113.39C628.36 110.23 629.75 106.13 629.71 101.83L629.43 72.9799C629.38 68.3099 627.62 63.8299 624.59 60.7099C624.36 60.4699 624.11 60.2299 623.86 59.9999C622.29 58.5499 620.5 57.3899 618.55 56.5699C616.78 55.8299 614.85 55.4499 612.81 55.4499H581.88V120.94Z"
          />
          <path
            d="M655.95 125.68C655.56 125.68 655.25 125.36 655.25 124.97V50.7999C655.25 50.4599 655.53 50.1799 655.87 50.1799H701.88L701.83 53.6699L661.44 53.6199C660.68 53.6199 660.06 54.2499 660.06 55.0099V84.5199C660.06 85.2899 660.68 85.9199 661.44 85.9199L687.2 85.8099C687.27 85.8099 687.33 85.8699 687.33 85.9399V89.0599C687.33 89.1299 687.27 89.1899 687.2 89.1899L661.43 89.2999C660.67 89.2999 660.05 89.9299 660.05 90.6899V120.9C660.05 121.67 660.67 122.3 661.43 122.3H701.91L701.86 125.69H655.95V125.68Z"
          />
        </svg>
      </router-link>
      <BaseThemeToggle />
    </div>
  </header>
</template>

<script>
import BaseThemeToggle from '@/components/Base/BaseThemeToggle'

export default {
  components: {
    BaseThemeToggle
  }
}
</script>
