<template>
  <ProjectsBlock />
</template>

<script>
import ProjectsBlock from '@/components/Projects/ProjectsBlock'

export default {
  components: {
    ProjectsBlock
  }
}
</script>
